import React, { useState } from "react";
import {
  ThemeProvider,
  Grid,
  Box,
  Button,
  Typography,
  CssBaseline,
  TextField,
  InputAdornment,
  Link,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";
import Lock from "@mui/icons-material/Lock";
import Mail from "@mui/icons-material/Mail";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database"; // Importing set and ref for Realtime Database
import { useNavigate } from "react-router-dom";
import Logo from "../assets/logo.jpg";

const theme = createTheme({
  palette: {
    primary: { main: "#F5B62A" },
    secondary: { main: "#383938" },
  },
});

const useStyles = {
  container: {
    backgroundImage: `url(${Logo})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    position: "relative",
    zIndex: 0,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Darker overlay for better contrast
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid gray",
    padding: "40px",
    backgroundImage:
      "linear-gradient(360deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7))",
    borderRadius: "24px",
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    width: "40%",
    "@media (max-width:600px)": {
      minWidth: "90%",
      padding: "20px",
    },
  },
  button: {
    marginTop: "20px",
    marginBottom: "10px",
    "&:hover": {
      backgroundColor: "#b22222", // Change color on hover
    },
  },
};
export default function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("Şifreler eşleşmiyor!");
      return;
    }

    const auth = getAuth();
    const db = getDatabase(); // Initialize the Realtime Database

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Store user information in the Realtime Database
      await set(ref(db, 'MobileUsers/' + user.uid), {
        email: user.email,
        uid: user.uid,
        // Add any additional user info here
      });

      alert("Kayıt başarılı!");
      navigate("/login"); // Redirect to login or another page
    } catch (error) {
      console.error("Kayıt hatası:", error);
      alert("Kayıt başarısız: " + error.message);
    }
  };

  return (
    <ThemeProvider theme={theme}>
    <Grid container component="main" sx={useStyles.container}>
      <div style={useStyles.overlay}>
        <CssBaseline />
        <Box sx={useStyles.paper}>
          <Typography component="div">
            <Box
              sx={{
                fontSize: {
                  xs: 30,
                  sm: 30,
                  md: 32,
                  lg: 35,
                },
                fontWeight: 900,
                marginBottom: 2,
              }}
            >
              KAYIT OL
            </Box>
          </Typography>
          <Typography component="div">
            <Box fontSize={16} m={1} textAlign="center">
              Hesabınızı oluşturmak için lütfen email ve şifrenizi girin
            </Box>
          </Typography>
          <form noValidate onSubmit={handleSubmit}>
            <Grid container direction="column" alignItems="center">
              <Grid item xs={12} sx={{ width: '100%' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Adresi"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Mail color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sx={{ width: '100%' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Şifre"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sx={{ width: '100%' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Şifreyi Onayla"
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock color="disabled" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  sx={useStyles.button}
                >
                  Kayıt Ol
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Link href="/reset-password" variant="body2" color="secondary">
                  Şifremi unuttum?
                </Link>
              </Grid>

              <Grid item xs={12}>
                <Typography component="div">
                  <Box fontSize={20} m={3} onClick={() => navigate("/login")}>
                    <Link href="#" color="secondary">
                      Hesabınız var mı? Giriş yapın.
                    </Link>
                  </Box>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    </Grid>
  </ThemeProvider>
  );
}