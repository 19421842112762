import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

const LoadingSpinner = ({ loading }) => {
  return (
    <div style={{minHeight:"100vh", width:"100vw", display:"flex", justifyContent:"center", alignItems:"center"}}>
      {" "}
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
        //   onClick={handleClose}
      ></Backdrop>
      <CircularProgress color="primary" size={75} />
    </div>
  );
};

export default LoadingSpinner;
