// src/pages/ForgotPassword.js
import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import Mail from "@mui/icons-material/Mail";
import Logo from "../assets/logo.jpg"; // Your logo path here
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../utils/useAuth'; // Import your useAuth hook
import LoadingSpinner from "../components/LoadingSpinner";

const theme = createTheme({
  palette: {
    primary: { main: "#F5B62A" },
    secondary: { main: "#383938" },
  },
});

const useStyles = {
  container: {
    backgroundImage: `url(${Logo})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    position: "relative",
    zIndex: 0,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    border: "1px solid gray",
    padding: "60px",
    backgroundImage: "linear-gradient(360deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.7))",
    borderRadius: "24px",
    boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    width: "40%",
    "@media (max-width:600px)": {
      minWidth: "100%",
      padding: "10px",
    },
  },
};

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();
  const { user } = useAuth();

  const handleResetPassword = async (event) => {
    event.preventDefault();
    setMessage("");
    setError("");
    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Şifre sıfırlama linki e-postanıza gönderildi.");
      setTimeout(() => navigate("/login"), 5000); // Redirect after 5 seconds
    } catch (err) {
      setError("E-posta gönderilirken hata oluştu: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  // Redirect if user is already logged in
  if (user) {
    navigate("/dashboard"); // Redirect to dashboard if already logged in
    return null; // Prevent rendering the component
  }

  return (
    <ThemeProvider theme={theme}>
      {isLoading && <LoadingSpinner loading={true} />}
      <Grid container component="main" sx={useStyles.container}>
        <div style={useStyles.overlay}>
          <CssBaseline />
          <Box sx={useStyles.paper}>
            <Typography component="div">
              <Box fontSize={30} fontWeight={900}>
                ŞİFREMİ UNUTTUM
              </Box>
            </Typography>
            <Typography component="div">
              <Box fontSize={16} m={1}>
                Şifrenizi sıfırlamak için lütfen email adresinizi giriniz.
              </Box>
            </Typography>
            <form noValidate onSubmit={handleResetPassword}>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Adresi"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Mail color="disabled" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Button type="submit" fullWidth variant="contained" color="secondary">
                    Şifreyi Sıfırla
                  </Button>
                </Grid>
                {message && (
                  <Grid item xs={9}>
                    <Typography color="green">{message}</Typography>
                  </Grid>
                )}
                {error && (
                  <Grid item xs={9}>
                    <Typography color="red">{error}</Typography>
                  </Grid>
                )}
                <Grid item xs={9}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/login")}
                  >
                    Geri Dön
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </div>
      </Grid>
    </ThemeProvider>
  );
}