import { ToastContainer } from "react-toastify";
import "./App.css";
import AppRouter from "./approuter/AppRouter";
import "react-toastify/dist/ReactToastify.css";
import { DataContext } from "./context/dataContextProvider";


function App() {
  return (
    <div>
      <DataContext>
        <AppRouter />
        <ToastContainer />
          
      </DataContext>

    </div>
  );
}

export default App;
