import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../utils/useAuth'; // Import your useAuth hook
import LoadingSpinner from '../components/LoadingSpinner'; // Import your loading spinner component

const PrivateRoute = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <LoadingSpinner loading={true} />; // Show loading indicator while checking auth
  }

  return user ? <Outlet /> : <Navigate to="/login" />; // Redirect to /login if not authenticated
};

export default PrivateRoute;