import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Register from "../pages/Register";
import ForgotPassword from "../pages/ResetPassword";
import NotFound from "../pages/Notfound"; // Import the NotFound component
import { useAuth } from "../utils/useAuth"; // Import the useAuth hook
import LoadingSpinner from "../components/LoadingSpinner";
import Navbar from "../components/Navbar";
import PrivateRoute from "./PrivateRoute";
import DeviceStatus from "../pages/Results";

const App = () => {
  const { user, loading } = useAuth(); // Get user and loading from useAuth

  // console.log("user", user)
  if (loading) {
    return (
      <div>
        <LoadingSpinner loading={true} />
      </div>
    ); // Show loading indicator while checking auth
  }

  return (
    <Router>
      {user ? <Navbar /> : <></>}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password" element={<ForgotPassword />} />
        <Route element={<PrivateRoute />}>
          <Route  path="/results" element={<DeviceStatus />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
        <Route path="*" element={<NotFound />} />{" "}
        {/* Catch-all for unmatched routes */}
      </Routes>
    </Router>
  );
};

export default App;
