// src/pages/Dashboard.js
import React, { useContext, useEffect, useState } from "react";

import { createTheme } from "@mui/material/styles";
import DeviceHub from "@mui/icons-material/DeviceHub";
import Logo from "../assets/logo.jpg"; // Your logo path here
import { useAuth } from '../utils/useAuth';
import LoadingSpinner from "../components/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../context/dataContextProvider";
import {
  ThemeProvider,
  Grid,
  Box,
  Button,
  Typography,
  CssBaseline,
  Card,
  CardContent,
  TextField,
  InputAdornment,
} from '@mui/material';

const theme = createTheme({
  palette: {
    primary: { main: "#F5B62A" },
    secondary: { main: "#383938" },
  },
});

const useStyles = {
  container: {
    backgroundImage: `url(${Logo})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    position: "relative",
    zIndex: 0,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Darker overlay for better contrast
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    background: "rgba(255, 255, 255, 0.9)",
    borderRadius: "16px",
    boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
    width: "80%",
    maxWidth: "400px",
    padding: "40px",
    textAlign: "center",
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    marginBottom: "20px",
  },
  subtitle: {
    fontSize: "16px",
    marginBottom: "20px",
  },
  button: {
    marginTop: "20px",
    '&:hover': {
      backgroundColor: '#b22222', // Darker shade on hover
    },
  },
};
export default function Dashboard() {
  const [deviceId, setDeviceId] = useState("");
  const [loading, setLoading] = useState(false); // Yükleme durumu
  const [showContent, setShowContent] = useState(true); // İçeriği gösterme durumu
  const navigate = useNavigate();
  const { loading: authLoading } = useAuth();
  const { handleConnectDevice,deviceFound, setDeviceFound } = useContext(MyContext);


  useEffect(() => {
    if (deviceFound) {
        navigate("/results");
    }
}, [deviceFound, navigate]); // Add navigate to the dependency array

  if (authLoading) {
    return <LoadingSpinner loading={true} />; // Auth kontrolü sırasında spinner göster
  }

  const connectDevice = async (event) => {
    let refresh;
    event.preventDefault();
    setLoading(true); 
    setShowContent(false); 
    setDeviceFound(false);
    localStorage.setItem('deviceId', deviceId);  
    await handleConnectDevice(deviceId, refresh=true);
    setLoading(false); 
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={useStyles.container}>
        <div style={useStyles.overlay}>
          <CssBaseline />
          {loading ? (
            <LoadingSpinner loading={true} />
          ) : (
            <Card sx={useStyles.card}>
              <CardContent>
                <Typography sx={useStyles.title}>
                  CİHAZA BAĞLAN
                </Typography>
                <Typography sx={useStyles.subtitle}>
                  Cihaza bağlanmak için lütfen cihaz ID'sini giriniz.
                </Typography>
                <form noValidate onSubmit={connectDevice}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item xs={12} sx={{ width: '100%' }}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="deviceId"
                        label="Cihaz ID'si"
                        name="deviceId"
                        autoComplete="device-id"
                        autoFocus
                        value={deviceId}
                        onChange={(e) => setDeviceId(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DeviceHub color="disabled" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        sx={useStyles.button}
                      >
                        Cihaza Bağlan
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          )}
        </div>
      </Grid>
    </ThemeProvider>
  );
}