// src/components/Navbar.js
import React, { useState } from "react";
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { getAuth } from "firebase/auth";
import { useAuth } from '../utils/useAuth'; // Import your useAuth hook
import { useHref, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MenuIcon from '@mui/icons-material/Menu';

const Navbar = () => {
  const { user } = useAuth(); // Get user info from the useAuth hook
  const navigate = useNavigate();
  const auth = getAuth();
  
  const [anchorEl, setAnchorEl] = useState(null); // State for the menu

  const handleLogout = async () => {
    try {
      await auth.signOut();
      localStorage.removeItem("deviceId"); // deviceId'yi kaldır
      // navigate("/login"); // Redirect to login after logout
      // useHref("/login")
      toast.success("Çıkış Yapıldı!");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="absolute" sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)", backdropFilter: "blur(10px)" }}>
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1, color: "#333" }}>
          WaterMaker
        </Typography>

        {/* Mobile Menu Button */}
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleMenuOpen}
          sx={{ display: { xs: 'block', md: 'none' }, color: "#333" }} // Dark color for hamburger icon
        >
          <MenuIcon sx={{ color: "#333" }} /> {/* Dark color for the icon */}
        </IconButton>

        {/* Desktop Menu */}
        {user && (
          <Typography variant="body1" sx={{ marginRight: 2, color: "#333", display: { xs: 'none', md: 'block' } }}>
            {user.email}
          </Typography>
        )}

        {/* Desktop Logout Button */}
        {user && (
          <Button color="inherit" onClick={handleLogout} sx={{ display: { xs: 'none', md: 'block' }, color: "#333" }}>
            Logout
          </Button>
        )}

        {/* Mobile Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>
            <Typography variant="body1" sx={{ color: "#333" }}>
              {user ? user.email : "Giriş Yap"}
            </Typography>
          </MenuItem>
          {user && (
            <MenuItem onClick={handleLogout}>
              <Typography variant="body1" sx={{ color: "#333" }}>
                Logout
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;