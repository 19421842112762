import React, { useState } from "react";
import {
  ThemeProvider,
  Grid,
  Box,
  Button,
  Typography,
  CssBaseline,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import Lock from "@mui/icons-material/Lock";
import Mail from "@mui/icons-material/Mail";
import Logo from "../assets/logo.jpg";
import { useNavigate } from "react-router-dom";
import googlePng from "../assets/google.png"
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useAuth } from "../utils/useAuth"; // Import your useAuth hook
import LoadingSpinner from "../components/LoadingSpinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const theme = createTheme({
  palette: {
    primary: { main: "#F5B62A" },
    secondary: { main: "#383938" },
  },
});

const useStyles = {
  container: {
    backgroundImage: `url(${Logo})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    position: "relative",
    zIndex: 0,
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Darker overlay for better contrast
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    background: "rgba(255, 255, 255, 0.9)",
    borderRadius: "16px",
    boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
    width: "90%",
    maxWidth: "400px",
    padding: "5px",
    textAlign: "center",
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    marginBottom: "20px",
  },
  subtitle: {
    fontSize: "16px",
    marginBottom: "20px",
  },
  button: {
    marginTop: "20px",
    marginBottom:"10px",
   backgroundColor: 'black',
  border: '1px solid #747775',
  color: '#ffffff',
  height: '40px',
  // paddingLeft: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '400px',
  minWidth: '250px',
  whiteSpace: 'nowrap',
  transition: 'background-color .218s, border-color .218s, box-shadow .218s',
  '&:disabled': {
    cursor: 'default',
    backgroundColor: '#ffffff61',
    borderColor: '#1f1f1f1f',
    '& .gsi-material-button-contents': {
      opacity: '38%',
    },
    '& .gsi-material-button-icon': {
      opacity: '38%',
    },
  },
  '&:hover:not(:disabled)': {
    backgroundColor: '#b22222', // Change color on hover
    boxShadow: '0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15)',
    '& .gsi-material-button-state': {
      backgroundColor: '#303030',
      opacity: '8%',
    },
  },
  '&:active:not(:disabled)': {
    '& .gsi-material-button-state': {
      backgroundColor: '#303030',
      opacity: '12%',
    },
  },
}
};

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth(); // Use your custom useAuth hook
  const auth = getAuth();

  const handleEmailLogin = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
      setLoading(false);
      toast.success("Giriş başarılı!");
      //   navigate("/dashboard");
    } catch (error) {
      console.error("Giriş hatası:", error);
      setLoading(false);
      toast.error("Giriş başarısız: " + error.message);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      setLoading(true);
      await signInWithPopup(auth, provider);
      setLoading(false);
      toast.success("Google ile giriş başarılı!");
      navigate("/dashboard");
    } catch (error) {
      console.error("Google Girişi hatası:", error);
      setLoading(false);
      toast.error("Google ile giriş başarısız: " + error.message);
    }
  };

  // Redirect if user is already logged in
  if (user) {
    if(localStorage.getItem("deviceId")){
      navigate("/results")
    }
    else{
      navigate("/dashboard");
    }

  }

  return (
    <ThemeProvider theme={theme}>
      {isLoading && <LoadingSpinner loading={true} />}
      <Grid container component="main" sx={useStyles.container}>
        <div style={useStyles.overlay}>
          <CssBaseline />
          <Card sx={useStyles.card}>
            <CardContent>
              <Typography sx={useStyles.title}>GİRİŞ YAP</Typography>
              <Typography sx={useStyles.subtitle}>
                Hesabınıza giriş yapmak için lütfen kullanıcı adı ve şifrenizi
                giriniz.
              </Typography>
              <form noValidate onSubmit={handleEmailLogin}>
                <Grid container direction="column" alignItems="center">
                  <Grid item xs={12} sx={{ width: "100%" }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Adresi"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Mail color="disabled" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ width: "100%" }}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Şifre"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock color="disabled" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Beni hatırla"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      sx={useStyles.button}
                    >
                      Giriş Yap
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      className="gsi-material-button"
                      variant="outlined"
                      onClick={handleGoogleLogin}
                      // disabled={disabled}
                      sx={{
                        borderRadius: "20px",
                        border: "1px solid #747775",
                        color: "#1f1f1f",
                        backgroundColor: "white",
                        height: "40px",
                        padding: "0 12px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        maxWidth: "400px",
                        minWidth: "min-content",
                        whiteSpace: "nowrap",
                        transition:
                          "background-color .218s, border-color .218s, box-shadow .218s",
                        "&:disabled": {
                          cursor: "default",
                          backgroundColor: "#ffffff61",
                          borderColor: "#1f1f1f1f",
                          "& .gsi-material-button-contents": {
                            opacity: "38%",
                          },
                          "& .gsi-material-button-icon": {
                            opacity: "38%",
                          },
                        },
                        "&:hover:not(:disabled)": {
                          boxShadow:
                            "0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15)",
                          "& .gsi-material-button-state": {
                            backgroundColor: "#303030",
                            opacity: "8%",
                          },
                        },
                        "&:active:not(:disabled)": {
                          "& .gsi-material-button-state": {
                            backgroundColor: "#303030",
                            opacity: "12%",
                          },
                        },
                      }}
                    >
                      <Box
                        className="gsi-material-button-icon"
                        sx={{
                          marginRight: "12px",
                          width: "20px",
                          height: "20px",
                        }}
                      >
                     <img
                        src={googlePng}
                        alt="google"
                        style={{ width: 20, marginRight: 15 }}
                      />
                      </Box>
                      <Typography
                        className="gsi-material-button-contents"
                        sx={{ fontWeight: 500 }}
                      >
                        Continue with Google
                      </Typography>
                      <div
                        className="gsi-material-button-state"
                        style={{
                          opacity: 0,
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                        }}
                      />
                    </Button>
                    {/* <Button
                      variant="outlined"
                      color="inherit"
                      onClick={handleGoogleLogin}
                      sx={{
                        marginTop: 2,
                        width: "100%",
                      }}
                    >
                      <img
                        src={googlePng}
                        alt="google"
                        style={{ width: 20, marginRight: 15 }}
                      />
                      {" ile giriş Yap"}
                    </Button> */}
                  </Grid>

                  <Grid item xs={12}>
                    <Link
                      href="/reset-password"
                      variant="body2"
                      color="secondary"
                    >
                      Şifremi unuttum?
                    </Link>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography component="div" sx={{ marginTop: 2 }}>
                      <Box fontSize={16} onClick={() => navigate("/register")}>
                        Hesabınız yoksa hemen üye olun?
                      </Box>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => navigate("/register")}
                    >
                      Üye Ol
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </div>
      </Grid>
    </ThemeProvider>
  );
}
