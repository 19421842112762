import React, { useContext, useState, useEffect, useRef } from "react";
import { createTheme } from "@mui/material/styles";
import Device from "../assets/device.jpg"; // Your logo path here
import { MyContext } from "../context/dataContextProvider"; // Context'i içe aktar
import { getDatabase, ref, update } from "firebase/database"; // Firebase'den gerekli fonksiyonları içe aktar
import { useAuth } from "../utils/useAuth";
import { useNavigate } from "react-router-dom";
import {
  ThemeProvider,
  Grid,
  Box,
  Button,
  Typography,
  CssBaseline,
  Paper,
  CardContent,
  IconButton,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import PowerIcon from "@mui/icons-material/Power"; // Open icon
import PowerOffIcon from "@mui/icons-material/PowerOff"; // Close icon
import PressureImage from "../assets/pressure.png";
import PressureImage2 from "../assets/pressure2.png";
import Temperature from "../assets/temperature.png";
import PpmImage from "../assets/ppm.png";
import Produced from "../assets/produced.png";
import Washer from "../assets/pressure-washer.png";
import { toast } from "react-toastify";
const theme = createTheme({
  palette: {
    primary: { main: "#F5B62A" },
    secondary: { main: "#383938" },
  },
});
const useStyles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 8,
  },
  card: {
    background: "rgba(255, 255, 255, 0.9)",
    borderRadius: "16px",
    boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
    width: "80%",
    maxWidth: "600px",
    padding: "10px",
  },
  title: {
    fontSize: "24px",
    fontWeight: 700,
    marginBottom: "5px",
    color: "white",
  },
  tableContainer: {
    marginTop: "5px",
    display: "flex",
  },
  table: {
    borderRadius: "15px",
    backgroundColor: "#C4D7FF",
    margin: "1px",
    // border:"1px solid black"
    // color:"red"
  },
  tableCell: {
    padding: "4px 8px", // Reduced padding for a compact look
    borderRadius: "15px",
  },
};

const DeviceStatus = () => {
  const {
    selectedProduct,
    handleConnectDevice,
    deviceStatus,
    setDeviceStatus,
  } = useContext(MyContext);
  const { user } = useAuth();
  const navigate = useNavigate();
  const selectedDevice = localStorage.getItem("deviceId");
  const [washingPopupOpen, setWashingPopupOpen] = useState(false);
  const [startupPopupOpen, setStartupPopupOpen] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [countRef, setCountRes] = useState(0);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const selectedProductRef = useRef(selectedProduct);
  useEffect(() => {
    selectedProductRef.current = selectedProduct;
  }, [selectedProduct]);


  useEffect(() => {
    handleConnectDevice(selectedDevice);
}, [selectedDevice]);
useEffect(() => {
  let interval;
  let refresh;
  
  
  if (deviceStatus) {
    interval = setInterval(() => {
      const currentNTC = selectedProductRef.current?.NTC;
      setCountRes(countRef + 1);
      if (selectedDevice) {
        handleConnectDevice(selectedDevice, (refresh = true));

        if (!selectedProduct.Status) {
          setDeviceStatus(false);
        }
        console.log(selectedProduct.Washing);
        if (selectedProduct.Washing) {
          setWashingPopupOpen(true); // Popup'ı aç
          // handleStatusChange(false);
          setStartupPopupOpen(false);
        }
        console.log(countRef)
        if (( currentNTC == 0) && countRef >= 3) {
          setDeviceStatus(false)
          setStartupPopupOpen(false);
          
          handleStatusChange(false)
          // setWashingPopupOpen(false);
          if (!toast.isActive("ntc-error")) {
            toast.error("Hata, Lütfen cihazı kontrol ediniz!!", {
              toastId: "ntc-error",
            });
          }
        }
      }
    }, 3000);
  }

  return () => clearInterval(interval);
}, [selectedDevice, deviceStatus, handleConnectDevice]);


useEffect(() => {
  let interval;
  let refresh;

  if (!deviceStatus) {
    interval = setInterval(() => {
      if (selectedDevice) {
        handleConnectDevice(selectedDevice, (refresh = true));
        if (selectedProduct.Status) {
          setDeviceStatus(true);
        }
        if (selectedProduct.NTC == 0) {
          setWashingPopupOpen(false);}

      }
    }, 10000);
  }

  return () => clearInterval(interval);
}, [selectedDevice, deviceStatus, handleConnectDevice]);

useEffect(() => {    
   if ((deviceStatus && isFirstRender)) {
    setIsFirstRender(false); // İlk render'dan sonra durumu güncelle
      return; // Eğer deviceStatus açıksa hiçbir şey yapma
  }
   if ((deviceStatus == false && isFirstRender)) {
    setIsFirstRender(false); // İlk render'dan sonra durumu güncelle
      return; // Eğer deviceStatus açıksa hiçbir şey yapma
  }
   else  if (deviceStatus && !isFirstRender) {
        setStartupPopupOpen(true);
        setCountdown(5); // Geri sayımı başlat

        const countdownInterval = setInterval(() => {

            setCountdown((prev) => {
                if (prev <= 1) {
                    clearInterval(countdownInterval);
                    checkNTCValue();
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(countdownInterval);
    }
    else if(!deviceStatus){
    setStartupPopupOpen(false);
    setCountRes(0)
    }
    
}, [deviceStatus]);

  const checkNTCValue = () => {

    const currentNTC = selectedProductRef.current?.NTC;

  if (currentNTC === 0) {
      if (!toast.isActive("ntc-error")) {
        toast.error("Hata, Lütfen cihazı kontrol ediniz!!", {
          toastId: "ntc-error",
        });
      }
      handleStatusChange(false);
      setWashingPopupOpen(false);
      setDeviceStatus(false);
    }

    setStartupPopupOpen(false);
  };
  const handleStatusChange = async (newStatus) => {
    setDeviceStatus(newStatus);
    let refresh;

    const db = getDatabase();
    const deviceRef = ref(db, `Products/${selectedDevice}`);

    try {
      await update(deviceRef, { Status: newStatus });

      const logRef = ref(db, `Products/${selectedDevice}/log`);
      const newLogEntry = {
        Updated_at: new Date().toISOString(),
        status: newStatus,
        UserID: user.uid,
        product: selectedDevice,
        userName: user.email,
      };

      await update(logRef, { [new Date().getTime()]: newLogEntry });
      handleConnectDevice(selectedDevice, (refresh = true));
    } catch (error) {
      console.error("Durum güncellenirken hata oluştu:", error);
    }
  };



  // Washing değeri false olduğunda popup'ı kapat
  useEffect(() => {
    if (selectedProduct && !selectedProduct.Washing) {
      setWashingPopupOpen(false);
    }
  }, [selectedProduct]);
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={useStyles.container}>
        {/* <div style={useStyles.overlay}> */}
        {/* <CssBaseline /> */}
        <Card
          sx={{
            backgroundColor: "#24323F", // Cam etkisi
            // backdropFilter: 'blur(10px)',
            borderRadius: "15px",
            // padding: 2,
            // marginTop: 70,
          }}
        >
          <CardContent>
            <Button
              variant="contained"
              onClick={() => navigate("/dashboard")}
              sx={{ marginBottom: "10px", color: "white" }}
            >
              Yeni Cihaz
            </Button>
            <Typography sx={useStyles.title}>Cihaz Durumu</Typography>
            {selectedDevice && (
              <Typography
                variant="body1"
                // marginBottom={2}
                sx={{ color: "white" }}
              >
                Bağlandı: {selectedDevice}
              </Typography>
            )}

            <Box display="flex" alignItems="center" marginBottom={2}>
              <Typography
                variant="body1"
                // marginRight={2}
                sx={{ color: "white" }}
              >
                Cihaz Durumu:
              </Typography>
              <IconButton
                onClick={() => handleStatusChange(true)}
                sx={{
                  color: deviceStatus ? "green" : "white",
                  fontSize: "50px",
                }}
              >
                <PowerIcon sx={{ fontSize: "inherit" }} />
              </IconButton>
              <IconButton
                onClick={() => handleStatusChange(false)}
                sx={{
                  color: !deviceStatus ? "red" : "white",
                  fontSize: "50px",
                }}
              >
                <PowerOffIcon sx={{ fontSize: "inherit" }} />
              </IconButton>
            </Box>

            {/* <Typography variant="h6" marginBottom={1}>Cihaz Bilgileri:</Typography> */}
            {selectedProduct ? (
              <div>
                <Dialog open={washingPopupOpen}>
                  <DialogTitle>Yıkama Devam Ediyor</DialogTitle>
                  <DialogContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p>Cihaz şu an yıkama modunda. Lütfen bekleyin...</p>
                    <img
                      src={Washer}
                      alt="Pressure"
                      style={{
                        width: "100px",
                        // marginLeft: "auto",
                        // marginRight: "auto",
                      }}
                    />
                  </DialogContent>
                  {/* <DialogActions>
                  <Button onClick={() => setWashingPopupOpen(false)} color="primary">
                    Kapat
                  </Button>
                </DialogActions> */}
                </Dialog>
                <Dialog
                  open={startupPopupOpen}
                  onClose={() => setStartupPopupOpen(false)}
                >
                  <DialogTitle>Cihaz Açılıyor</DialogTitle>
                  <DialogContent>
                    <Typography variant="body1">
                      Cihaz açılıyor... Lütfen bekleyiniz: {countdown} saniye
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setStartupPopupOpen(false)}
                      color="primary"
                    >
                      Kapat
                    </Button>
                  </DialogActions>
                </Dialog>
                <Grid container spacing={2}>
                  {/* HP */}
                  <Grid item xs={6} sm={4}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: 1,
                        borderRadius: "15px",
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        backgroundColor: "#0D1D2A",
                      }}
                    >
                      <Typography
                        style={{ textAlign: "left", color: "white" }}
                        variant="h5"
                      >
                        HP
                      </Typography>
                      <img
                        src={PressureImage}
                        alt="Pressure"
                        style={{
                          width: "50px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          padding: "5px",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ color: "white" }} variant="h5">
                          {selectedProduct.HP}
                        </Typography>
                        <Typography
                          sx={{ color: "white", marginLeft: 1 }}
                          variant="p"
                        >
                          {"  " + " / bar"}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: 1,
                        borderRadius: "15px",
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        backgroundColor: "#0D1D2A",
                      }}
                    >
                      <Typography
                        style={{ textAlign: "left", color: "white" }}
                        variant="h5"
                      >
                        LP
                      </Typography>
                      <img
                        src={PressureImage2}
                        alt="Pressure"
                        style={{
                          width: "50px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          padding: "5px",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ color: "white" }} variant="h5">
                          {selectedProduct.LP}
                        </Typography>
                        <Typography
                          sx={{ color: "white", marginLeft: 1 }}
                          variant="p"
                        >
                          {"  " + " / bar"}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                  
                  <Grid item xs={6} sm={4}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: 1,
                        borderRadius: "15px",
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        backgroundColor: "#0D1D2A",
                      }}
                    >
                      <Typography
                        style={{ textAlign: "left", color: "white" }}
                        variant="h5"
                      >
                        NTC
                      </Typography>
                      <img
                        src={Temperature}
                        alt="Pressure"
                        style={{
                          width: "50px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ color: "white" }} variant="h5">
                          {selectedProduct.NTC}
                        </Typography>
                        <Typography
                          sx={{ color: "white", marginLeft: 1 }}
                          variant="p"
                        >
                          {"  " + " / °C"}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: 1,
                        borderRadius: "15px",
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center",
                        textAlign: "center",
                        backgroundColor: "#0D1D2A",
                      }}
                    >
                      <Typography
                        style={{ textAlign: "left", color: "white" }}
                        variant="h5"
                      >
                        Tuzluluk
                      </Typography>
                      <img
                        src={PpmImage}
                        alt="Pressure"
                        style={{
                          width: "50px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ color: "white" }} variant="h5">
                          {selectedProduct.PPM}
                        </Typography>
                        <Typography
                          sx={{ color: "white", marginLeft: 1 }}
                          variant="p"
                        >
                          {"  " + " / ppm"}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: 1,
                        borderRadius: "15px",
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center",
                        textAlign: "center",
                        backgroundColor: "#0D1D2A",
                      }}
                    >
                      <Typography
                        style={{ textAlign: "left", color: "white" }}
                        variant="h5"
                      >
                        Üretilen
                      </Typography>
                      <img
                        src={Produced}
                        alt="Pressure"
                        style={{
                          width: "50px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ color: "white" }} variant="h5">
                          {selectedProduct.Produced}
                        </Typography>
                        <Typography
                          sx={{ color: "white", marginLeft: 1 }}
                          variant="p"
                        >
                          {"  " + " / litre"}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Paper
                      elevation={3}
                      sx={{
                        padding: 1,
                        borderRadius: "15px",
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center",
                        textAlign: "center",
                        backgroundColor: "#0D1D2A",
                      }}
                    >
                      <Typography
                        style={{ textAlign: "left", color: "white" }}
                        variant="h5"
                      >
                        Washing
                      </Typography>
                      <img
                        src={Washer}
                        alt="Pressure"
                        style={{
                          width: "50px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ color: "white" }} variant="h5">
                          {selectedProduct.Washing ? "Aktif" : "Pasif"}
                        </Typography>
                        <Typography
                          sx={{ color: "white", marginLeft: 1 }}
                          variant="p"
                        >
                          {/* {"  " + " / tuzluluk"} */}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <Typography>Cihaz bilgileri mevcut değil.</Typography>
            )}
          </CardContent>
        </Card>
        {/* </div> */}
      </Grid>
    </ThemeProvider>
  );
};

export default DeviceStatus;
